@value screen-sm, screen-md, screen-lg, screen-xl, screen-xxl from "~/src/styles/breakpoints.module.css";

/* Hidden breakpoints */

@media (max-width: calc(screen-sm - 1px)) {
  .hidden-xs {
    display: none !important;
  }
}

@media (max-width: calc(screen-md - 1px)) {
  .hidden-sm {
    display: none !important;
  }
}

@media (max-width: calc(screen-lg - 1px)) {
  .hidden-md {
    display: none !important;
  }
}

@media (max-width: calc(screen-xl - 1px)) {
  .hidden-lg {
    display: none !important;
  }
}

@media (max-width: calc(screen-xxl - 1px)) {
  .hidden-xl {
    display: none !important;
  }
}

.thin-scrollbar,
.thin-scrollbar :global(.ant-popover-inner-content) /* Antd's Popover component uses a scrollable container that doesn't have a className prop. */ {
  scrollbar-color: var(--ant-color-border) transparent;
  scrollbar-width: thin;
}

.thin-scrollbar::-webkit-scrollbar-thumb,
.thin-scrollbar :global(.ant-popover-inner-content)::-webkit-scrollbar-thumb {
  background-color: var(--ant-color-border);
}

.thin-scrollbar::-webkit-scrollbar-track,
.thin-scrollbar :global(.ant-popover-inner-content)::-webkit-scrollbar-track {
  background-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar-corner,
.thin-scrollbar :global(.ant-popover-inner-content)::-webkit-scrollbar-corner {
  background-color: transparent;
}

.thin-scrollbar::-webkit-scrollbar,
.thin-scrollbar :global(.ant-popover-inner-content)::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

/**
 * .hover-visible:
 * Elements that needs to be hidden and displayed on container hover.
 *
 * e.g.
 * <div className="hover-visible-container">
 *   <div className="hover-visible" >
 *     <Button type="danger">Remove</Button>
 *   </div>
 * </div>
 */
.hover-visible {
  opacity: 0;
}

.hover-visible-container:hover .hover-visible,
.hover-visible-container:focus-within .hover-visible {
  opacity: 1 !important;
  transition: opacity 0.15s ease-out;
}

/* Add cursor pointer. */
.clickable {
  cursor: pointer;
}
